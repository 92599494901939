import * as React from 'react'
import { useRef } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { popupManager, popupDataManager } from '@/recoil/popup'
import { marketCodeManager, marketCodesListState } from '@/recoil/marketCode'
import { MAP_POPUP_ID } from '@/components/Popup/config'
import StoreLocator from '@/components/StoreLocator'
import { isMobileState } from '@/recoil/layout'

import { isEcommerceState } from '@/recoil/isEcommerce'

import Layout from '@/layouts/Default'

import { useWatchSections } from '@/hook/index'

import ProductHero from '@/components/ProductHero'

import Taste from '@/components/Taste'
import BlockTextImage from '@/components/Block/TextImage'
import BlockTextImages from '@/components/Block/TextImages'
import BlockVideoSlider from '@/components/Block/Sliders/VideoSlider'
import BlockQuote from '@/components/Block/Quote'
import Button from '@/components/Button'
import Link from '@/components/Link'
import SectionsNav from '@/components/SectionsNav'
import WinesSlider from '@/components/WinesSlider'
import TextImage from '@/components/Block/TextImage'
import { PRODUCT_FEATURE } from '@/utils/gtmEvents'
import isPOSInCurrentMarket from '@/utils/isPOSInCurrentMarket'
import resolveFileURL from '@/utils/resolveFileURL'
import resolveAssetURL from '@/utils/resolveAssetURL'

const ProductPage = ({ data, pageContext: { requireAgeGate, pagePath, marketCode: pageMarketCode } }) => {
  const isMobile = useRecoilValue(isMobileState)
  const marketCode = useRecoilValue(marketCodeManager)

  const document = data.drupal.otherProductQuery

  const gtm = (action, product) => {
    window.dataLayer &&
      window.dataLayer.push({
        event: PRODUCT_FEATURE,
        item_name: product,
        interaction_type: action
      })
  }

  const products = data.drupal.productsQuery.entities

  const productsSliderItems = products.map((category) => ({
    img: category.fieldWineCatPrimaryVintage.entity.fieldWineProdPackshotShadow
      .url,
    art: category.fieldWineCatPrimaryVintage.entity
      .fieldWineProductBackillustrat.url,
    name: category.name,
    price: category.fieldWineCategoryFromPrice,
    colorBg:
      category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackcolor,
    colorCta:
      category.fieldWineCatPrimaryVintage.entity.fieldProductDiscoverColorCta,
    title: category.fieldWineCatPrimaryVintage.entity.fieldCategoryProductName,
    text: category.fieldWineCatPrimaryVintage.entity.fieldWineProductShortDesc,
    path: category.fieldWineCatPrimaryVintage.entity.path.alias
  }))

  const getCapacities = (data) => {
    return data.map(c => {
      return c.entity.fieldCapacity.entity
    })
  }

  return (
    <Layout
      entityBundle='other_products'
      path={ pagePath }
      requireAgeGate={ requireAgeGate }
      plainHeader
      hrefLangsLocales={ ['en-nz'] }
      pageMarketCode={ pageMarketCode }
    >
      <Helmet>
        <title>
          {document.fieldWineProductMetaTags?.entity.fieldMetaTitle}
        </title>
        <meta
          name='description'
          content={
            document.fieldWineProductMetaTags?.entity.fieldMetaDescription
          }
        />
        <meta
          property='og:title'
          content={ document.fieldWineProductMetaTags?.entity.fieldMetaTitle }
        />
        <meta
          property='og:description'
          content={
            document.fieldWineProductMetaTags?.entity.fieldMetaDescription
          }
        />
        <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
        <meta property='og:site_name' content='Cloudy Bay' />
        <meta property='og:type' content='website' />
        {document.fieldWineProductMetaTags?.entity.fieldMetaOpenGraphImage && (
          <meta
            property='og:image'
            content={
              document.fieldWineProductMetaTags?.entity.fieldMetaOpenGraphImage
                ?.url
            }
          />
        )}
      </Helmet>
      <ProductHero
        title={ document.title }
        page='other-product'
        description={ document.fieldWineProductDescription }
        images={ [document.fieldWineProductPackshot.url] }
        recommendedTemperature={ document.fieldWineProductConsumption }
        cellaringPotential={ document.fieldWineProductCellaring }
        bottlingDate={ document.fieldWineProductBottling }
        alcoholPercentage={ document.fieldWineProductAlcohol }
        gtm={ gtm }
        bgColor={ document.fieldWineProductBackcolor }
        price={ document.fieldWineProductPrice }
        capacities={ getCapacities(document.fieldCapacitySku) }
        sku={ document.fieldCapacitySku }
        pelorusURL={ document.fieldWineProductPelorusLink?.uri }
      />
      {document.fieldWineProductTastingNotes
        ? (
          <TextImage
            title={ document.fieldWineProductTasteTitle }
            subtitle={ document.fieldWineProductTastesubtitle }
            actions={
            document.fieldWineProductTastingNotes
              ? [
                <Button
                  key={ 1 }
                  to={ resolveAssetURL(document.fieldWineProductTastingNotes.entity.url) }
                  target='_blank;'
                >
                  See Tasting note
                </Button>
                ]
              : []
          }
            text={ document.fieldWineProductTasteDesc }
            image={ document.fieldWineProductTastingImage?.url }
            imageOrder='right'
          />
          )
        : (
            ''
          )}
      <WinesSlider id='textcercle1' title='Discover other wines' items={ productsSliderItems } />
      <div id='storeLocatorProductPage' />
      <StoreLocator pageType='Other Products' />
    </Layout>
  )
}

export const query = graphql`
  query OtherProductQuery($nid: String!) {
    drupal {
      otherProductQuery: nodeById(id: $nid) {
        title
        nid
        path {
          alias
        }
        ... on Drupal_NodeOtherProducts {
          fieldWineProductMetaTags {
            entity {
              ... on Drupal_ParagraphMetaTags {
                fieldMetaTitle
                fieldMetaDescription
                fieldMetaOpenGraphImage {
                  url
                }
              }
            }
          }
          fieldWineProductName
          fieldWineProdTasteDescTitle
          fieldWineProductPackshot {
            url
            alt
          }
          fieldWineProductDescription
          fieldWineProductImageGallery {
            entity {
              ... on Drupal_ParagraphWineProductImageGallery {
                fieldWineProductImageItem {
                  entity {
                    ... on Drupal_ParagraphWineProductImageGalleryItem {
                      fieldWineProductImage {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          fieldWineProductPelorusLink {
            uri
          }
          fieldWineProductPrice
          fieldCapacitySku {
            entity {
              ... on Drupal_ParagraphWineProductSku {
                fieldCapacity {
                  entity {
                    ... on Drupal_TaxonomyTermBottleCapacities {
                      name
                      uuid
                      tid
                    }
                  }
                }
                fieldWinedirectSku
              }
            }
          }
          fieldWineProductConsumption
          fieldWineProductCellaring
          fieldWineProductBottling
          fieldWineProductAlcohol
          fieldWineProductBackcolor
          fieldWineProductTasteTitle
          fieldWineProductTasteDesc
          fieldWineProductTastesubtitle
          fieldWineProductTastingNotes {
            entity {
              url
              uri {
                url
              }
            }
          }
          fieldWineProductTastingImage {
            url
            alt
            title
          }
          fieldWineProduct1stKeyTaste {
            entity {
              ... on Drupal_TaxonomyTermPrimaryTastingNotes {
                fieldTastingNoteIcon {
                  url
                  title
                }
              }
            }
          }
          fieldWineProduct2ndKeyTaste {
            entity {
              ... on Drupal_TaxonomyTermPrimaryTastingNotes {
                fieldTastingNoteIcon {
                  url
                  title
                }
              }
            }
          }
          fieldWineProduct3rdKeyTaste {
            entity {
              ... on Drupal_TaxonomyTermPrimaryTastingNotes {
                fieldTastingNoteIcon {
                  url
                  title
                }
              }
            }
          }
        }
      }
      productsQuery: taxonomyTermQuery(
        limit: 1000
        offset: 0
        filter: {
          conditions: [
            { operator: EQUAL, field: "vid", value: ["wine_category"] }
          ]
        }
      ) {
        entities {
          ... on Drupal_TaxonomyTermWineCategory {
            name
            fieldWineCategorySlugUrl
            fieldWineCatPrimaryVintage {
              entity {
                ... on Drupal_NodeWineProduct {
                  nid
                  path {
                    alias
                  }
                  title
                  fieldProductDiscoverColorCta
                  fieldWineProductBackcolor
                  fieldWineProductBackillustrat {
                    url
                  }
                  fieldWineProductShortDesc
                  fieldWineProductName
                  fieldCategoryProductName
                  fieldWineProductDiscoverCta {
                    title
                  }
                  fieldWineProdPackshotShadow {
                    url
                  }
                  fieldWineProductPelorusLink {
                    uri
                  }
                }
              }
            }
            fieldWineCategoryFromPrice
          }
        }
      }
    }
  }
`

export default ProductPage
